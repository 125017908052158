import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/glues"
import { Quality } from "page_components/home"

const SystemWet = ({ data, location }) => {
  const pageData = data?.allWpPageSystem?.nodes[0]
  const pageDataHome = data?.allWpPageHome?.nodes[0]

  return (
    <Layout location={location}>
      <Breadcrumbs title="Wallpaper Adhesives" />
      <PageHeader title="Wallpaper Adhesives" />
      <Content pageData={pageData} />
      <Quality pageData={pageDataHome} />
    </Layout>
  )
}

export const Head = ({ data }) => (
  <Seo
    title={
      data?.allWpPageSystem?.nodes[0]?.seo?.title
        ? data?.allWpPageSystem?.nodes[0]?.seo?.title
        : data?.allWpPageSystem?.nodes[0]?.title
    }
    description={
      data?.allWpPageSystem?.nodes[0]?.seo?.metaDesc &&
      data?.allWpPageSystem?.nodes[0]?.seo?.metaDesc
    }
  />
)

export const query = graphql`
  query {
    allWpPageSystem: allWpPage(filter: { id: { eq: "cG9zdDoxNzI0OQ==" } }) {
      nodes {
        content
        seo {
          title
          metaDesc
        }
      }
    }
    allWpPageHome: allWpPage(filter: { id: { eq: "cG9zdDoxNzIxNg==" } }) {
      nodes {
        acfHome {
          qualityHeader
          qualitySubHeader
          qualityContentHeader
          qualityContentDescription
          qualityContentImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default SystemWet
